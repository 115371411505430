export async function logTalentAd() {
	const talentPageLink = 'www.justwatch.com/us/talent';
	const talentAdBody = `
        JustWatch is looking for talented engineers and many other roles across the team. 
        If you're interested, read more on our talent page: ${talentPageLink}

        We're excited to hear from you!
    `;
	console.log("%c We're hiring!", 'font-size: 72px; color: #1f9a48;');
	console.log(talentAdBody);
}
